import {
  AppWrapper,
  CircularProgress,
  Typography,
  useSavedPath,
} from "@aiops/styleguide";
import { useEffect, useState } from 'react';
import {
  userIsSignedIn,
  redirectUserToAuthSite,
  signInWithAuthCode,
} from "@aiops/auth-util";
import * as singleSpa from "single-spa";

export default function Root() {
  const [failedAuth, setFailedAuth] = useState<boolean>(false);

  const { getPath } = useSavedPath();

  /**
   * Redirects the user where they were trying to go before they got redirected
   * to the authorization flow (or to the root of the app, if no value is found
   * for this).
   */
  const redirectToAppPage = () => {
    singleSpa.navigateToUrl(getPath());
  }

  useEffect(() => {

    // If the user arrives here with no code value as a query parameter (ie 
    // manually), either push them to the root of the app if they're logged in,
    // or push them to the auth url if they're not.
    const handleNoAuthCode = async () => {
      const loggedIn = await userIsSignedIn();
      if (loggedIn) {
        redirectToAppPage();
      } else {
        redirectUserToAuthSite();
      }
    }

    // If the user arrives here with an auth code, try to use it to sign them in
    // and then push them where they're trying to go.
    const handleAuthCode = async (code: string) => {
      // Use the auth code to try to sign the user in
      await signInWithAuthCode(code);

      // Make sure that it worked
      const loggedIn = await userIsSignedIn();

      if (loggedIn) {
        redirectToAppPage();
      } else {
        setFailedAuth(true);
      }
    }

    const queryParams = new URLSearchParams(window.location.search);
    const authCode = queryParams.get('code');

    if (authCode) {
      handleAuthCode(authCode);
    } else {
      handleNoAuthCode();
    }
  }, []);

  // Warn the user that the app was unable to log them in using the access code.
  // The point is to avoid an infinite loop where logging in with an access code
  // fails and immediately redirects them to the SSO page which redirects them
  // back here, where the new code fails...
  const unauthedWarning = () => {
    return (
      <Typography variant="heading7-light">
        Unable to confirm access
      </Typography>
    )
  }

  return (
    <AppWrapper>
      <div className="col">
        {
          failedAuth
            ? unauthedWarning()
            : <CircularProgress />
        }
      </div>
    </AppWrapper>
  )
}
